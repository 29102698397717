// Updated AboutPage.js

import React from 'react';
import './AboutPage.css';
import utils from '../../utils';
import aboutImg from '../../images/about_us.jpg';

const AboutPage = () => {
  return (
    <div className="about-container">x
      <div className="about-section">
        <div className="about-section-image">
          <img src={aboutImg} alt='' />
        </div>
        <div className="about-section-text">
        <h2>About Us</h2>
          <p>
          Let's Serve is a dynamic platform designed to bridge the gap between event organizers and individuals eager to contribute to social causes. We empower organizers of various social initiatives to share their events with a broad audience, providing essential details such as the nature of the event, location, and specific needs—be it volunteers, donations, or both. From individuals planning a weekend teaching session for underprivileged children, groups organizing beach clean-up operations, to community leaders arranging food donations for the homeless during special occasions, our platform welcomes a diverse range of events aimed at making a positive impact.
          </p>
          <p>
Organizers can effortlessly post their upcoming events, specifying exactly what kind of support they require. Meanwhile, those looking to give back can browse these listings to find opportunities that resonate with their passions and schedules. Whether you're interested in contributing your time, resources, or both, Let's Serve makes it easy to connect directly with organizers and participate in meaningful work that benefits the community. Our mission is to streamline the process of finding and offering help, ensuring that every act of kindness reaches its intended destination.            </p>
        </div>
      </div>
      <div className="about-section reverse">
        <div className="about-section-image">
          <img src={utils.getProjectImage('ourMission')} alt='' />
        </div>
        <div className="about-section-text">
        <h2>How You Can Help</h2>
          <p>
          At Let's Serve, we are committed to a wide array of social causes that offer everyone a chance to make a difference. Our initiatives include weekend teaching programs, where volunteers dedicate a few hours to tutor underprivileged children, helping them unlock their full potential. We also organize beach clean-up events, uniting community members to preserve the beauty and health of our coastal environments. Moreover, our special event food donations provide warm meals to the homeless, bringing comfort and sustenance to those in need during times of celebration. Whether it’s imparting knowledge, protecting our planet, or sharing a meal, Let's Serve creates pathways for individuals to contribute to their community in meaningful ways. By joining our efforts, you can partake in a range of activities aligned with your interests and schedules, from environmental conservation to educational support and beyond.
          </p>
           </div>
      </div>
      <div className="about-section">
        <div className="about-section-image">
          <img src={utils.getProjectImage('help')} alt='' />
        </div>
        <div className="about-section-text">
        <h2>Our Mission</h2>

          <p>To empower individuals and organizations to contribute to society by providing a platform that facilitates volunteering and donations. We aim to support a wide range of causes and make it easier for everyone to take part in charitable activities.</p>
          <p>In addition to participating in events, your financial support helps us run this platform and develop more useful features. If you wish to contribute, please find the donate button located in the footer of our website. Your contributions ensure that we can continue to serve our community effectively.</p>

        </div>
      </div>
    </div>
  );
};

export default AboutPage;
