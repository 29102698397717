import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import makeRequest from '../../hooks/makeRequest';
import './ProjectDetail.css';
import utils from '../../utils';
import ShareButton from '../../ShareButton';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import DisqusComments from '../DisqusComments';

const ProjectDetail = ({ handleVote, handleVoteUpdate }) => {
  const [project, setProject] = useState(null);
  const [comments, setComments] = useState([]);
  const { projectId } = useParams();
  const url = window.location.href;
  const [displayDate, setDisplayDate] = useState('');

  useEffect(() => {
    const fetchProject = async () => {
      // Replace the URL with your actual endpoint to fetch project details
      try {
        const data = await makeRequest(`/projects/${projectId}`, {}, 'GET');
        setProject(data.project);
        setDisplayDate( utils.getDatebyTimeZone(data.project.event_date));
      } catch (error) {
        console.error('Fetching error:', error);
      }
    };

    const fetchComments = async () => {
      // Replace the URL with your actual endpoint to fetch comments
      const projectComments = await makeRequest(`/comments/${projectId}`, {}, 'GET');
      setComments(projectComments);
    };

    fetchProject();
    // fetchComments();
  }, [projectId, handleVote]);

  return (
    <div className="project-detail">
      <h1 className="project-title">{project?.name}</h1>
      <img src={utils.getProjectImage(project?.category)} alt={project?.name} />
      <div className="project-description">
        <p>{project?.description}</p>
      </div>
      <div className="project-meta">
        <p><strong>Location</strong> {`${project?.address}, ${project?.city}, ${project?.state}, ${project?.country} - ${project?.zip_code}`}</p>
        <p><strong>Date</strong> {displayDate}</p>
        <p><strong>Duration</strong> {project?.duration} {project?.duration>1?'Days':'Day'}</p>
        <p><strong>Type</strong> {utils.camelCaseToTitleCase(project?.category)}</p>
        {project?.money_required && <p><strong>Money Goal</strong> {utils.formatCurrency(project?.money_goal, project?.country)}</p>}
        {project?.volunteer_required && <p><strong>Volunteer Goal</strong> {project?.volunteer_goal} volunteers</p>}
      </div>

      {project?.share_contact && (
        <div className="project-contact">
          <p><strong>Organizer Contact</strong></p>

          <p>
            {/* <strong>Email</strong>   */}
            <a href={`mailto:${project.email}`} title="Send Email" >
              ✉️ {project?.email}
            </a>
          </p>
          {project?.phone && (
          <p>
            {/* <strong>Phone</strong>  */}
            <a href={`tel:${project?.phone}`} title="Call Now">
              📞 {project?.phone}
            </a>
          </p>
          )}
        </div>
      )}
      <div className="detail-vote-share-section">
        <div className="vote-count upvote" onClick={() => handleVote(projectId, 'up').then(() => handleVoteUpdate(projectId, 'up', true))}>
          <FaThumbsUp />
          <span>{project?.upvote_count}</span>
        </div>
        <ShareButton shareUrl={url} />
        <div className="vote-count downvote" onClick={() => handleVote(projectId, 'down').then(() => handleVoteUpdate(projectId, 'down', true))}>
          <FaThumbsDown />
          <span>{project?.downvote_count}</span>
        </div>
      </div>
      
      {/* <div className="project-stats">
        <p><strong>Upvotes:</strong> {project?.upvote_count}</p>
        <p><strong>Downvotes:</strong> {project?.downvote_count}</p>
        <p><strong>Total Votes:</strong> {project?.total_vote_count}</p>
        <p><strong>Clicks:</strong> {project?.click_count}</p>
      </div>
      <div className="project-status">
        <p><strong>Status:</strong> {project?.status}</p>
      </div> */}

<DisqusComments
  pageUrl={`${window.location.href}#disqus_thread`}
  pageIdentifier={window.location.pathname.split("/").pop()}
/>
    </div>
  );
};



export default ProjectDetail;