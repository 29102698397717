// ProjectList.js

import React from 'react';
import ProjectCard from '../ProjectCard/ProjectCard';
import './ProjectList.css';
import utils from '../../utils';

const ProjectList = ({ filteredProjects, isLoading, handleVote, handleVoteUpdate, status }) => {

  const past_events = []
  const future_events = []

  for (let i = 0; i < filteredProjects.length; i++) {
    const eventDate = new Date(utils.getDatebyTimeZone(filteredProjects[i].event_date));
    const currentDate = new Date();
    eventDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
    if (eventDate < currentDate) {
      past_events.push(filteredProjects[i])
    } else {
      future_events.push(filteredProjects[i])
    }
  }
  status === 'past' ? filteredProjects = past_events : filteredProjects = future_events;

  return (
    <div className="project-list-page">
      <div className="project-list">
        {isLoading &&  <div className="loading-container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="svg-loading"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          cx="50"
          cy="50"
          fill="none"
          stroke="#1d3f72"
          strokeWidth="10"
          r="35"
          strokeDasharray="164.93361431346415 56.97787143782138"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1s"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
          ></animateTransform>
        </circle>
      </svg>
    </div>}
        {!isLoading && filteredProjects.map(project => (
          <ProjectCard key={project.id} project={project} handleVote={handleVote} handleVoteUpdate={handleVoteUpdate} />
        ))}
      </div>
    </div>
  );
};

export default ProjectList;
