import utils from '../utils';

const makeRequest = async (path, data, method, needsAuth) => {
    let token = null;
    if (needsAuth) {
        token = localStorage.getItem('authToken');
        if (!token) {
            // Handle the case where there is no token - e.g., redirect to login
            throw new Error('Authentication failed');
        }
    }
    const host = utils.getBaseBackendURL();

    const headers = {
        'Content-Type': 'application/json'
    };

    if (needsAuth) {
        headers['X-Auth-Token'] = token;
    }
    // Conditionally prepare the body only for methods that can have a body
  const body = method !== 'GET' && method !== 'HEAD' ? JSON.stringify(data) : undefined;


    const options = {
        method,
        headers,
    };
// Include body in the options if it's not a GET or HEAD request
if (body) {
    options.body = body;
  }
    return await fetch(`${host}${path}`, options)
        .then(async (res) => {
            if (!res.ok) {
                // If the response is not okay, parse the error response and throw it
                const errorResponse = await res.json();
                throw { status: res.status, body: errorResponse };
            }
            if (res.headers.get('content-type')?.includes('application/json')) {
                return res.json();
            } else {
                throw new Error('Non-JSON response received');
            }
        })
        .then((data) => {
            return data;
        })
        .catch((err) => {
            console.log('err: path, body, method: ', { err, path, options });
            throw err;
        });
};

export default makeRequest;