// Header.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using react-router for navigation
import './Header.css'; // Import your Header.css file
import logo from '../../images/logo.png';

const SearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isFocused, setIsFocused] = useState(false);


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onSearch(searchTerm);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, onSearch, isFocused]);


  return (
    <div className="search-bar">
      <input
        type="text"
        placeholder="🔍 name, location, or category"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
    </div>
  );
};
const Header = ({ name, onSearch, onLoginRedirect, onLogout }) => {
  const isActive = (pathname) => location.pathname === pathname;

  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showEventSubMenu, setShowEventSubMenu] = useState(false);
  const showSearch = ((location.pathname === '/past-project-list') || location.pathname === '/upcoming-project-list')
  return (
    <header className="header">
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="Let's Serve Together" />
        </Link>
      </div>
      <div className="breadcrumb">
        <nav >
          <ul>
            <li><Link to="/" className={isActive('/') ? 'active' : ''}>Home</Link></li>


            <li>{
                <div onMouseEnter={() => setShowEventSubMenu(true)} onMouseLeave={() => setShowEventSubMenu(false)}>
                 <div className={isActive('/upcoming-project-list') || isActive('/past-project-list')  ? 'user-welcome-active' : 'user-welcome-text'}>Events</div> 
                  {showEventSubMenu &&
                    <ul className="submenu">
                      <li><Link to="/upcoming-project-list">Upcoming Events</Link></li>
                      <li><Link to="/past-project-list">Past Events</Link></li>
                    </ul>
                  }
                </div> 
            }
            </li>

            <li><Link to="/add-project" className={isActive('/add-project') ? 'active' : ''}>Add Event</Link></li>
            <li><Link to="/faq" className={isActive('/faq') ? 'active' : ''}>FAQ</Link></li>
            <li><Link to="/about-us" className={isActive('/about-us') ? 'active' : ''}>About Us</Link></li>

            <li>{
              name ?
                <div onMouseEnter={() => setShowSubMenu(true)} onMouseLeave={() => setShowSubMenu(false)}>
                  <div className={isActive('/user/dashboard') ? 'user-welcome-active' : 'user-welcome-text'}>Welcome, {name}</div>
                  {showSubMenu &&
                    <ul className="submenu">
                      <li><Link to="/user/dashboard">Dashboard</Link></li><
                        li><Link onClick={onLogout}>Logout</Link></li>
                    </ul>
                  }
                </div>
                : <Link onClick={onLoginRedirect}>Login</Link>
            }
            </li>
          </ul>
        </nav>
      </div>
      {showSearch ? <SearchBar onSearch={onSearch} /> : null}
    </header>
  );
};

export default Header;
