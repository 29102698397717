import homeless from './images/homeless.jpg';
import bloodDonation from './images/blood_donation.jpg';
import oldage from './images/oldage.jpg';
import orphanage from './images/orphanage.jpg';
import donation from './images/donation.jpg';
import medical from './images/medical.jpg';
import education from './images/education.jpg';
import sports from './images/sports.jpg';
import aboutUs from './images/about_us.jpg';
import help from './images/help.jpg';
import ourMission from './images/our_mission.jpg';
import defaultImage from './images/default.jpg';
import home from './images/home.jpg';
import moment from 'moment-timezone';

const getBaseBackendURL = () => {
  return window.location.hostname === "localhost" ? "http://localhost:5001" : "https://api.letsserve.org";
};

const getBaseWindowURL = () => {
  return window.location.hostname === "localhost" ? "http://localhost:3000" : "https://www.letsserve.org";
}
const getDatebyTimeZone = (date) => {
  const timezoneMatch = date.match(/\[(.*?)\]$/);
  const timezone = timezoneMatch ? timezoneMatch[1] : 'UTC';
  // Parse the date ignoring the timezone part in square brackets
  const formattedDate = moment.tz(date.split('[')[0], timezone)
    .format('DD-MMM-YYYY'); // Customize the format as needed
  return formattedDate;
}
const formatDate =(date) =>{
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return `${day}-${monthNames[monthIndex]}-${year}`;
}
const loadScript = (src, id) => {
  return new Promise((resolve, reject) => {
    let script = document.getElementById(id);
    if (!script) {
      script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      script.id = id;
      script.async = true;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        reject(false);
      };
      document.body.appendChild(script);
    } else {
      resolve(true);
    }
  });
};

const formatCurrency = (amount, country) => {
  let currency = 'USD'; // Default currency
  switch (country) {
    case 'United States':
      currency = 'USD';
      break;
    case 'India':
      currency = 'INR';
      break;
    case 'United Kingdom':
      currency = 'GBP';
      break;
    // Add more countries and their currencies as needed
    default:
      currency = 'USD';
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(amount);
};

const camelCaseToTitleCase = (camelCase) => {
  if (!camelCase) {
    return camelCase;
  }

  // Add a space before each uppercase letter, then capitalize the first letter of the resulting string
  const result = camelCase.replace(/([A-Z])/g, " $1").trim();
  return result.charAt(0).toUpperCase() + result.slice(1);
};

// Import additional images here

const categoryImages = {
  homeless,
  bloodDonation,
  oldage,
  orphanage,
  donation,
  medical,
  education,
  sports,
  aboutUs,
  help,
  ourMission,
  home,
  defaultImage
};
const getCountryList = () => {
  // const countries =  [
  //   "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda",
  //   "Argentina", "Armenia", "Australia", "Austria", "Azerbaijan",
  //   "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin",
  //   "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi",
  //   "Cabo Verde", "Cambodia", "Cameroon", "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia",
  //   "Comoros", "Congo, Democratic Republic of the", "Congo, Republic of the", "Costa Rica", "Cote d'Ivoire", "Croatia", "Cuba", "Cyprus", "Czech Republic",
  //   "Denmark", "Djibouti", "Dominica", "Dominican Republic",
  //   "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia",
  //   "Fiji", "Finland", "France",
  //   "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana",
  //   "Haiti", "Honduras", "Hungary",
  //   "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy",
  //   "Jamaica", "Japan", "Jordan",
  //   "Kazakhstan", "Kenya", "Kiribati", "Korea, North", "Korea, South", "Kosovo", "Kuwait", "Kyrgyzstan",
  //   "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg",
  //   "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar",
  //   "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway",
  //   "Oman",
  //   "Pakistan", "Palau", "Palestine", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal",
  //   "Qatar",
  //   "Romania", "Russia", "Rwanda",
  //   "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria",
  //   "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu",
  //   "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "Uruguay", "Uzbekistan",
  //   "Vanuatu", "Vatican City", "Venezuela", "Vietnam",
  //   "Yemen",
  //   "Zambia", "Zimbabwe"
  // ];
  const countries = ["India", "United Kingdom", "United States"];
  return countries;
}
const getProjectImage = (category) => {
  return categoryImages[category] || categoryImages.defaultImage; // Provide a default image if the category doesn't match
};

export default {
  getBaseBackendURL,
  getBaseWindowURL,
  getProjectImage,
  camelCaseToTitleCase,
  getCountryList,
  formatCurrency,
  getDatebyTimeZone,
  loadScript,
  formatDate
};