// ProjectCard.js

import React from 'react';
import './ProjectCard.css';
import { Link } from 'react-router-dom';
import utils from '../../utils';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import ShareButton from '../../ShareButton';
import ReactGA from 'react-ga4';

const ProjectCard = ({ project, handleVote, handleVoteUpdate }) => {
  project.image = utils.getProjectImage(project.category);
  project.url = `${utils.getBaseWindowURL()}/projects/${project.id}`;

  const formattedDate = utils.getDatebyTimeZone(project.event_date);
  
  const handleProjectClick = (projectId, action) => {
    console.log('Inside deal card project click');
    ReactGA.event({
        category: 'Project',
        action: action,
        label: `Project ID: ${projectId}`
    });
};
  return (
    <div className="project-card">
      <Link to={`/projects/${project.id}`} onClick={() => handleProjectClick(project.id, 'Deal Card Click')}  style={{ textDecoration: 'none', color: 'inherit' }}>
        <div className="project-info">

          <h3>{project.name}</h3>
          {project.image && <img src={project.image} alt={project.name} />}
          {/* <p><strong>Description:</strong> {project.description}</p> */}
          <p><strong>Location</strong> {project?.city}</p>
          <p><strong>Date</strong> {formattedDate}</p>
          <p><strong>Type</strong> {utils.camelCaseToTitleCase(project.category)}</p>
          <p><strong>Duration</strong> {project.duration} {project.duration>1?'Days':'Day'}</p>
          <p><strong>Money Required</strong> {project.money_required ? 'Yes' : 'No'}</p>
          <p><strong>Volunteers Required</strong> {project.volunteer_required ? 'Yes' : 'No'}</p>
        </div>

      </Link>

      <div className="vote-share-section">
        <div className="vote-count upvote" onClick={() => handleVote(project.id, 'up').then(() => handleVoteUpdate(project.id, 'up', true))}>
          <FaThumbsUp />
          <span>{project.upvote_count}</span>
        </div>
        <ShareButton shareUrl={project.url} />
        <div className="vote-count downvote" onClick={() => handleVote(project.id, 'down').then(() => handleVoteUpdate(project.id, 'down', true))}>
          <FaThumbsDown />
          <span>{project.downvote_count}</span>
        </div>
      </div>
    </div>

  );
};

export default ProjectCard;
