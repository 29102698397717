export const ErrorCodeMessage = {
    1000: 'Store parser not implemented',
    1001: 'Invalid URL format',
    1002: 'Invalid status',
    1003: 'Invalid vote type',
    1004: 'Vote already exists',
    1005: 'Project not found',
    1006: 'Authentication failed',
    1007: 'Error decoding token',
    1008: 'No email or name found in token',
    1009: 'Error fetching user',
    1010: 'Error getting projects',
    1011: 'Error creating project',
    1012: 'Store not supported',
    1013: 'Error creating user',
    1014: 'Error creating category',
    1015: 'Sorry, project already exists, please try to add another project, or report existing project if it is expired',
    500: 'Internal server error',
};