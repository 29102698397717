import React, { useState } from 'react';
import makeRequest from '../../hooks/makeRequest';
import './Tab.css';
import utils from '../../utils';

const PendingProjects = ({ pendingProjects }) => {
    const [errors, setErrors] = useState({}); // New state for storing errors
    const [updatedProjects, setUpdatedProjects] = useState({}); // New state to track updated projects

    const handleDealAction = async (projectId, action, reason) => {
        try {
            setErrors(prevErrors => ({ ...prevErrors, [projectId]: undefined }));

            await makeRequest(`/project/action/${projectId}`, { action, reason, projectId }, 'POST', true);

            setUpdatedProjects(prevUpdated => ({ ...prevUpdated, [projectId]: true }));
        } catch (err) {
            // Update error state for the specific project
            setErrors(prevErrors => ({ ...prevErrors, [projectId]: err.message || 'Error occurred' }));
        }
    };

    console.log('Rendering PendingProjects: pendingProjects:', pendingProjects)
    return (
        <div className="pending-projects-container">
            {pendingProjects.map((project, index) => (
                <div key={index} className={`pending-project ${updatedProjects[project._id] ? 'project-updated' : ''}`}>
                    <div className="tab-project-info">
                        <div className="project-title">
                            <a href={`${utils.getBaseWindowURL()}/projects/${project._id}`} target="_blank" rel="noopener noreferrer">{project.name}</a>
                        </div>
                        <div className="project-store">
                            <strong>Address</strong> {project.address}, {project.city}, {project.state}, {project.country}
                        </div>
                        <div className="project-price">
                            <strong>Event Date</strong> {utils.getDatebyTimeZone(project.event_date)}
                        </div>
                        <div className="project-price">
                            <strong>Status</strong> {project.status}
                        </div>
                        <div className="project-contact">
                            {project.share_contact && (
                                <div>
                                    <p><strong> Admin Contact</strong></p>
                                    <p><strong>Phone</strong> {project.phone}</p>
                                    <p><strong>Email</strong> {project.email}</p>
                                </div>
                            )}                        
                        </div>
                    </div>

                    <div className="project-actions">
                        {/* Action buttons here */}
                        <button className='tab-project-actions-approve' onClick={() => handleDealAction(project._id, 'approved')}>Approve</button>
                        <button className='tab-project-actions-reject' onClick={() => handleDealAction(project._id, 'rejected', 'invalid')}>Reject as Invalid</button>
                        <button className='tab-project-actions-reject' onClick={() => handleDealAction(project._id, 'rejected', 'spam')}>Reject as Spam</button>
                        <button className='tab-project-actions-reject' onClick={() => handleDealAction(project._id, 'rejected', 'inappropriate')}>Reject as Inappropriate</button>


                        {/* Add more reject reasons as needed */}
                    </div>
                    {errors[project._id] && <div className="tab-error-message">{errors[project._id]}</div>}

                </div>
            ))}
        </div>
    );
};

export default PendingProjects;
