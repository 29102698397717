import React, { useState } from 'react';
import './Tab.css';
import ReactGA from 'react-ga4';

const Tab = ({ children }) => {

    const handleDashbaordTabClick = (label) => {
        console.log('Tab Click:', label);
        ReactGA.event({
            category: 'Dashboard',
            action: 'Tab click',
            label: `${label}`
        });
    };

    // Initialize the activeTab state with the label of the first child
    const [activeTab, setActiveTab] = useState(children[0]?.props?.label);
    return (
        <div className="tab">
            <div className="tab-buttons">
                {React.Children.map(children, (child) => (
                    child &&
                    <button
                        className={child?.props?.label === activeTab ? 'tab-button active' : 'tab-button'}
                        onClick={() => {
                            handleDashbaordTabClick(child?.props?.label); 
                            setActiveTab(child?.props?.label)}
                        }
                    >
                        {child?.props?.label}
                    </button>
                ))}
            </div>
            <div className="tab-tab-content">
                {React.Children.map(children, child => (
                    child?.props?.label === activeTab ? child : undefined
                ))}
            </div>
        </div>
    );
};

export default Tab;
