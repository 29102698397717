// AddProject.js
import React, { useState, useEffect, useRef } from 'react';
import './AddProject.css';
import { ErrorCodeMessage } from '../../error_code';
import makeRequest from '../../hooks/makeRequest';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'; // useParams to get project id from URL
import moment from 'moment-timezone';
import ReactGA from 'react-ga4';


import utils from '../../utils';

const AddProject = () => {

  const { projectId } = useParams(); // Get projectId from URL
  const [projectData, setProjectData] = useState({
    name: '',
    address: '',
    city: '',
    state: '',
    country: 'United States',
    zip_code: '',
    event_date: '',
    duration: '',
    money_required: false,
    money_goal: '',
    volunteer_required: false,
    volunteer_goal: '',
    email: '',
    phone: '',
    share_contact: false,
    category: '',
    description: ''
  });

  useEffect(() => {
    const fetchProjectData = async () => {
      if (projectId) {
        const data = await makeRequest(`/projects/${projectId}`, {}, 'GET');
        setProjectData(data.project || {}); // Assuming the API returns an object with a 'project' key
      }
    };

    if (projectId) {
      fetchProjectData();
    } else {
      // Reset form when there's no projectId (new project scenario)
      setProjectData({
        name: '',
        address: '',
        city: '',
        state: '',
        country: 'United States',
        zip_code: '',
        event_date: '',
        duration: '',
        money_required: false,
        money_goal: '',
        volunteer_required: false,
        volunteer_goal: '',
        email: '',
        phone: '',
        share_contact: true,
        category: '',
        description: ''
      });
    }
  }, [projectId]);

  const errorRef = useRef(null);

  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate('/');
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProjectData({
      ...projectData,
      [name]: type === 'checkbox' ? checked : value
    });
  };


  const handleAddProjectClick = (country, action, status) => {
    ReactGA.event({
        category: 'Add Project',
        action: action,
        label: `Country: ${country}, Status: ${status}`
    });
    console.log('Inside add project click',country, action, status);
};

const handleUpdateProjectClick = (country, action, status) => {

  ReactGA.event({
        category: 'Update Deal',
        action: action,
        label: `Country: ${country}, Status: ${status}`
    });
    console.log('Inside update project click',country, action, status);
};
  const validateForm = async () => {
    if (!projectData.name || !projectData.zip_code || !projectData.event_date || !projectData.duration || !projectData.email) {
      errorRef.current?.scrollIntoView({ behavior: 'smooth' });
      setErrorMessage('Please fill all required fields');
      return false;
    } else {
      setErrorMessage(''); // Clear error message if the form is valid
      return true;
    }
  };

  const addProject = async () => {

    // Assuming projectData.event_date is in 'YYYY-MM-DD' format
const eventDate = projectData.event_date + 'T00:00:00'; // Add time part to make it a complete datetime string
const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// Convert eventDate to the user's timezone and format it
const eventDateWithTimezone = moment.tz(eventDate, userTimezone).format();

    const projectSubmissionData = { ...projectData, event_date: eventDateWithTimezone };

    try {
      await makeRequest('/user/project', { project: { ...projectSubmissionData } }, 'POST', true)
        .then(navigateToHome)
        .catch((err) => {
          const errorMessage = err.message || 'An error occurred';
          errorRef.current?.scrollIntoView({ behavior: 'smooth' });
          setErrorMessage(errorMessage);
        });
       handleAddProjectClick(projectData.country, 'Add Project', 'Success');
    } catch (err) {
      // Assuming err contains the error response directly
      const errorCode = err.body?.errorCode || '500';
      const errorMessage = ErrorCodeMessage[errorCode] || err.body?.errorMessage || 'An error occurred';
      const statusCode = err.status?.toString() || '500';

      handleAddProjectClick(projectData.country, 'Add Proect', `Failure:${errorMessage}`);
      console.error('Error Code:', errorCode);
      console.error('Error Message:', errorMessage);
      console.error('statusCode :', statusCode);

      errorRef.current?.scrollIntoView({ behavior: 'smooth' });
      setErrorMessage(errorMessage);
    }
  };

  const updateProject = async (projectId) => {
    try {
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const eventDateWithTimezone = `${projectData.event_date}T00:00:00[${userTimezone}]`;

      const projectSubmissionData = { ...projectData, event_date: eventDateWithTimezone };

      await makeRequest(`/user/project/${projectId}`, { project: { ...projectSubmissionData } }, 'PUT', true)
        .then(navigate('/user/dashboard')
        )
        .catch((err) => {
          const errorMessage = err.message || 'An error occurred';
          errorRef.current?.scrollIntoView({ behavior: 'smooth' });
          setErrorMessage(errorMessage);
        });
        handleUpdateProjectClick(dealData.country, 'Update Deal', 'Success')

    } catch (err) {
      const errorCode = err.body?.errorCode || '500';
      const errorMessage = ErrorCodeMessage[errorCode] || err.body?.errorMessage || 'An error occurred';
      const statusCode = err.status?.toString() || '500';

      console.error('Error Code:', errorCode);
      console.error('Error Message:', errorMessage);
      console.error('statusCode :', statusCode);
      handleUpdateProjectClick(dealData.country, 'Update Deal', `Failure:${errorMessage}`);
      errorRef.current?.scrollIntoView({ behavior: 'smooth' });
      setErrorMessage(errorMessage);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isFormValid = await validateForm();
    if (!isFormValid) {
      return;
    }
    if (projectId) {
      updateProject(projectId).then(() => {
        // history.push('/dashboard'); // Redirect to dashboard after update

      });
    } else {
      addProject();

    }
  };

  return (
    <div className='project-form-container'>
      <form className="add-project-form" onSubmit={handleSubmit}>
        <h2> Submit your Project Details</h2>
        <div ref={errorRef} className="error-message">{errorMessage}</div>
        <div className="required-fields">* Required Fields</div>
        <input type="text" name="name" placeholder="Project Name *" value={projectData.name} onChange={handleChange} required />
        <textarea name="description" placeholder="Project Description" value={projectData.description} onChange={handleChange}></textarea>
        <select name="category" value={projectData.category} onChange={handleChange}>
          <option value="">Select Category</option>
          {/* Add your categories here. Make sure the value are camelcase and matches with the image names with underscore 
          eg: bloodDonation is category and file name is blood_donation */}
          <option value="homeless">Homeless Support</option>
          <option value="bloodDonation">Blood Donation</option>
          <option value="orphanage">Orphan Support</option>
          <option value="oldage">Old Age Support</option>
          <option value="education">Education Support</option>
          <option value="medical">Medical Camp</option>
          <option value="sports">Sports Camp</option>
          <option value="other">Other</option>
        </select>

        <div className="section location">
          <h3>Location*</h3>
          <input type="text" name="address" placeholder="Address" value={projectData.address} onChange={handleChange} />
          <input type="text" name="city" placeholder="City" value={projectData.city} onChange={handleChange} required />
          <input type="text" name="state" placeholder="State" value={projectData.state} onChange={handleChange} required />

          <select name="country" value={projectData.country} onChange={handleChange} required>
            <option value="">Select Country</option>
            {utils.getCountryList().map((country, index) => (
              <option key={index} value={country}>{country}</option>
            ))}
          </select>
          <input type="text" name="zip_code" placeholder="Zip Code" value={projectData.zip_code} onChange={handleChange} required />

        </div>


        <input
          type="date"
          name="event_date"
          placeholder="Event Date *"
          value={projectData.event_date}
          onChange={handleChange}
          required
          min={new Date().toISOString().split('T')[0]} // Restricts to future dates
        />
        <input
          type="number"
          name="duration"
          placeholder="Duration (# of days) *"
          value={projectData.duration}
          onChange={handleChange}
          required
          onWheel={(e) => e.target.blur()} // Prevents scroll from changing value
        />
        <label>
          <input type="checkbox" name="money_required" checked={projectData.money_required} onChange={handleChange} />
          Money Needed?
        </label>
        {projectData.money_required && (
          <input
            type="number"
            name="money_goal"
            placeholder="Amount Needed"
            value={projectData.money_goal}
            onChange={handleChange}
            onWheel={(e) => e.target.blur()} // Apply this to all number inputs
          />
        )}
        <label>
          <input type="checkbox" name="volunteer_required" checked={projectData.volunteer_required} onChange={handleChange} />
          Volunteers Needed?
        </label>
        {projectData.volunteer_required && (
          <input
            type="number"
            name="volunteer_goal"
            placeholder="# of Volunteers Needed"
            value={projectData.volunteer_goal}
            onChange={handleChange}
            onWheel={(e) => e.target.blur()}
          />
        )}
        <input type="email" name="email" placeholder="Email ID *" value={projectData.email} onChange={handleChange} required />
        <input
          type="text"
          name="phone"
          placeholder="Phone Number"
          value={projectData.phone}
          onChange={handleChange}
          pattern="^\+?\d{0,13}" // Adjust regex based on your requirements for phone numbers
          title="Please enter a valid phone number." // Tooltip for invalid input
        />        <label>
          <input type="checkbox" name="share_contact" checked={projectData.share_contact} onChange={handleChange} />
          Show Contact to Users
        </label>
        <button type="submit">{projectId ? 'Update Project' : 'Add Project'}</button>
      </form>
    </div>
  );
};

export default AddProject;
