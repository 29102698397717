import React, { useEffect } from 'react';

const DisqusComments = ({ pageUrl, pageIdentifier }) => {
  useEffect(() => {
    // Function to add the Disqus script to the page
    const addDisqusScript = () => {
      const script = document.createElement('script');
      script.src = 'https://letsserve-org.disqus.com/embed.js';
      script.setAttribute('data-timestamp', +new Date());
      document.body.appendChild(script);
    };

    const addDisqusCountScript = () => {
      const script = document.createElement('script');
      script.src = 'https://letsserve-org.disqus.com/count.js';
      script.async = true;
      document.body.appendChild(script);
    };
    // Configuration variables: Defined in the global scope
    window.disqus_config = function () {
      this.page.url = pageUrl; // Replace pageUrl with your page's canonical URL variable
      this.page.identifier = pageIdentifier; // Replace pageIdentifier with your page's unique identifier variable
    };

    // Add Disqus script to the page
    addDisqusScript();
    addDisqusCountScript();
    // Cleanup function to remove script when component unmounts
    return () => {
      // Find and remove the script from the body
      const script = document.querySelector('script[src="https://letsserve-org.disqus.com/embed.js"]');
      const countScript = document.querySelector('script[src="https://letsserve-org.disqus.com/count.js"]');

      if (script) {
        document.body.removeChild(script);
      }
      if (countScript) {
        document.body.removeChild(countScript);
      }
      // Reset Disqus config
      window.disqus_config = null;
    };
  }, [pageUrl, pageIdentifier]); // Effect dependencies

  return (
    <div id="disqus_thread"></div>
  );
};

export default DisqusComments;
