import React from 'react';
import './HomePage.css';
import { event } from 'react-ga4';

const HomePage = () => {
  const handleViewEventClick = () => {
    console.log('View Event Click');
    event({
        category: 'Upcoming Event',
        action: 'View Event Click',
        label: 'Home Page Event Click'
    });
};

  return (
    <div className="homepage">
      <div className="content-and-image">
        <div className="content-section">
          <h1>Welcome to Let's Serve</h1>
          <p>Join our community to make a positive impact.</p>
          <div className="key-points">
            <ul>
              <li>Let's Serve bridges the gap between organizers seeking support and those ready to offer help, be it through volunteering or donations.</li>
              <li>Discover a variety of impactful activities, from educational initiatives to environmental clean-ups.</li>
              <li>Directly connect with organizers to contribute where it matters most.</li>
              <li>Whether you're offering time or resources, your efforts foster community change.</li>
              <li>Join us to make a difference: explore, connect, and contribute.</li>
            </ul>
          </div>
          <div className="action-links">
            <a href="/upcoming-project-list" onClick={() => handleViewEventClick()}>View Events</a>
          </div>
        </div>
        <div className="image-section"> </div>
      </div>
    </div>
  );
};

export default HomePage;

