import utils from "../utils";
import React, { useEffect } from 'react';


const Footer = () => {
    useEffect(() => {
        // Load the Stripe script
        utils.loadScript('https://js.stripe.com/v3/buy-button.js', 'stripe-buy-button-script').then(() => {
            const stripeButton = document.querySelector('#stripe-button-container stripe-buy-button iframe');
            // if (stripeButton) {
            //   stripeButton.style.width = 'fit-content';
            //   stripeButton.style.padding = '0 5px';
            // }
        }).catch(error => {
            console.error('Stripe script failed to load', error);
        });
    }, []);
    return (
        <footer className="footer">
            <p>Non Profit Organization to bring the like minded people together to serve the World</p>
            
            <div id="stripe-button-container">
                {/* Directly using the Stripe Buy Button custom element */}
                <stripe-buy-button
                    buy-button-id="buy_btn_1OjVOEE4G8DJxdrufyFOzi7l"
                    publishable-key="pk_live_51NSRHpE4G8DJxdruSwC1xlnUJisbNNdqg2SkXk4hDUDXETDoZXH0O86mDkySrbiYzpyZeyoTlYVaCXxjF1CFl1HQ00K3w0k3pg"
                >
                </stripe-buy-button>
                <p className="footer-contact-email">
                    <a href='mailto:contact@letsserve.org' title="Send Email" >
                        ✉️  contact@letsserve.org
                    </a>
                </p>
            </div>
        </footer>
    )
};

export default Footer;