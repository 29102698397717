import utils from '../utils';

const ProtectedRoute = ({ children }) => {
    const isAuthenticated = !!localStorage.getItem('authToken')/* Logic to determine if the user is authenticated */;
    return isAuthenticated ? children : handleLoginRedirect();
};

const handleLoginRedirect = () => {
    window.location.href = `${utils.getBaseBackendURL()}/connect/google`;
}

export default ProtectedRoute;
