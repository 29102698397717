import { useState, useEffect } from 'react';
import makeRequest from './makeRequest';

function useBackendUpdate(initialState, data) {
  const [kbState, setKbState] = useState(initialState);

  useEffect(() => {
    const updateBackend = async () => {
      try {
        console.log('kbState: ', kbState);
        const response = await fetch('http://localhost:5001/knowledge-check-blocks/state', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(kbState),
        });

        if (!response.ok) {
          throw new Error('Failed to update backend');
        }
      } catch (error) {
        console.error('Error updating backend:', error);
      }
    };

    updateBackend();
  }, [kbState, data.kb_id]);

  return [kbState, setKbState];
}

const getProjects = async () => {
const { projects } = await makeRequest('/projects/latest', null, 'GET');

  const fetchedProjects = [
    { id: 1, name: 'Project 1', address: 'Location 1', date: '2023-01-01', image: 'image_url_1' },
    { id: 2, name: 'Project 2', address: 'Location 2', date: '2023-01-01', image: 'image_url_2' },
    { id: 3, name: 'Project 3', address: 'Location 3', date: '2023-01-01', image: 'image_url_3' },
    { id: 4, name: 'Project 4', address: 'Location 3', date: '2023-01-01', image: 'image_url_3' },
    { id: 5, name: 'Project 5', address: 'Location 3', date: '2023-01-01', image: 'image_url_3' },
    { id: 6, name: 'Project 6', address: 'Location 3', date: '2023-01-01', image: 'image_url_3' },

    // ... more projects
  ];

  return projects?projects:fetchedProjects;
}

export {
  useBackendUpdate,
  getProjects
}