// Updated FAQPage.js

import React, { useState } from 'react';
import './FAQ.css';

const FAQPage = () => {


  const faqs = [
    {
      category: 'General FAQs',
      questions: [
        {
          question: `What is Let's Serve organzation?`,
          answer: `Let's Serve is a platform dedicated to connecting volunteers and donors with various social causes and events such as homeless help, medical camps, old age support, teaching opportunities, food donations, and orphanage support. Our mission is to facilitate meaningful contributions, whether through volunteering time or financial support.`
       },
        {
          question: 'How can I find events near me?',
          answer: 'You can easily find events by using the search function on our homepage. You can search by event name, location, or event type to find opportunities that match your interest and availability.'
        },
        {
          question: 'How do I sign up to volunteer for an event?',
          answer: 'To volunteer for an event, click on the event card to view more details, and if the organizer has shared their contact information, you can directly reach out to them to express your interest and plan your participation.'
        }
      ]
    },
    {
      category: 'Event Organization FAQs',
      questions: [
        {
          question: 'How can I add an event to Let\'s Serve?',
          answer: 'If you\'re an event organizer looking to recruit volunteers or raise funds, you can add your event by navigating to the "Add Project" page and filling out the event details form. You\'ll need to provide information such as event name, location, date, duration, and whether you need volunteers or financial support.'
        },
        {
          question: 'Do I need to create an account to volunteer or donate?',
          answer: 'To add an event, creating an account is necessary, and currently, we only support logging in through Google. This streamlined process ensures a secure and convenient way for you to join our community and participate in events.'
        },
        {
          question: 'Can I update the information about my event after posting it?',
          answer: 'Yes, you have the flexibility to edit or delete your event details directly from the "Projects by You" section within the dashboard. This feature allows you to keep your event information up to date and make any necessary changes.'
        }
      ]
    },
    {
      category: 'Donation FAQs',
      questions: [
        {
          question: 'How can I donate to an event or cause?',
          answer: 'To continuously aid and sustain the Let\'s Serve organization, you\'re encouraged to click the support button found in our website\'s footer. For contributions directed towards a specific event, please choose the event of interest and utilize the provided contact details to make your donation.'
         },
        // {
        //   question: 'Are my donations through [Website Name] tax-deductible?',
        //   answer: '[Adjust based on your organization\'s status and the legal framework of your country. If donations are tax-deductible, explain the process to obtain a receipt.]'
        // }
      ]
    },
    {
      category: 'Volunteer FAQs',
      questions: [
        {
          question: 'Do I need to create an account to volunteer or donate?',
          answer: 'No, users are not required to log in to view events or make donations. You can freely browse through the events and contribute to causes without creating an account.'
        },
        {
          question: 'How can I search for specific types of events, like food donations or teaching opportunities?',
          answer: 'Use the search functionality in the header of our website to filter events by type, location, or name. This feature allows you to find events that align with your interests and availability.'
        }
      ]
    },
    {
      category: 'Technical FAQs',
      questions: [
        {
          question: 'What should I do if I encounter technical issues on the site?',
          answer: 'If you\'re experiencing technical difficulties, please contact our support team, contact@letsserve.org. We are committed to ensuring a smooth experience on our platform and will assist you as soon as possible.'
        },
        // {
        //   question: 'How can I receive updates about new events or opportunities?',
        //   answer: '[If you have a newsletter or update system, explain how users can subscribe or opt-in. If not, suggest how they can stay informed, such as regularly visiting the site or following your social media channels.]'
        // }
      ]
    }
  ];

  const [activeIndex, setActiveIndex] = useState('0-0');

  const toggleFAQ = index => {
    console.log('index:', index);
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <h2>Frequently Asked Questions</h2>
      {faqs.map((faqCategory, categoryIndex) => (
        <div key={categoryIndex}>
          <h3>{faqCategory.category}</h3>
          <div className="faqs">
            {faqCategory.questions.map((faq, index) => (
              <div 
                className={`faq ${activeIndex === categoryIndex + '-' + index ? 'active' : ''}`} 
                key={categoryIndex + '-' + index} 
                onClick={() => toggleFAQ(categoryIndex + '-' + index)}
              >
                <div className="faq-question">
                  {faq.question}
                </div>
                <div className="faq-answer">
                  {faq.answer}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQPage;
