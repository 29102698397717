// App.js
import React, { useState, useEffect } from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from '../Header/Header';
import ProjectList from '../ProjectList/ProjectList.js'; 
import AddProject from '../AddProject/AddProject'; // Import your AddProject component
import FAQ from '../FAQ/FAQ'; // Import your FAQ component
import './App.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { getProjects } from '../../hooks/useBackendUpdate';
import utils from '../../utils';
import ProtectedRoute from '../ProtectedRoute';
import UserDashboard from '../UserDashboard/UserDashboard';
import ProjectDetail from '../ProjectDetail/ProjectDetail.js';
import makeRequest from '../../hooks/makeRequest';
import SideBar from '../SideBar';
import Footer from '../Footer';
import HomePage from '../HomePage/HomePage';
import AboutPage from '../AboutPage/AboutPage.js';
import ReactGA from 'react-ga4';


ReactGA.initialize('G-VQQBVM8PD1');


const App = () => {

  // Here you might have your state for isLoggedIn and username


  // State to hold the list of projects
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [user, setUser] = useState({ name: ''});

  const location = useLocation();

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // Fetch the list of projects from your API or service
    if (location.pathname === '/past-project-list' || location.pathname === '/upcoming-project-list') {
      console.log('Inside past/upcoming project list');
      fetchProjects();
    }
    handleUserState(location);
  }, [location]);

  useEffect(() => {
    console.log('Inside breadcrumb click event',location.pathname);

    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location.pathname]);


  useEffect(() => {
    if (searchTerm === '') {
      setFilteredProjects(projects); // Reset to all projects when search term is cleared
    } else {
      const filtered = filterProjects(projects, searchTerm);
      setFilteredProjects(filtered);
    }
  }, [searchTerm]);

  const fetchProjects = async () => {
    try {
      // show loading spinner 
      setIsLoading(true); // Begin loading

      const fetchedProjects = await getProjects();

      setProjects(fetchedProjects);
      // sort fetched projects by upvote_count - downvote_count and then by date
      const sortedProjects = fetchedProjects.sort((a, b) => {
        const aScore = a.upvote_count - a.downvote_count;
        const bScore = b.upvote_count - b.downvote_count;
        if (aScore > bScore) return -1;
        if (aScore < bScore) return 1;
        return new Date(b.created_at) - new Date(a.created_at);
      });
      setFilteredProjects(sortedProjects);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }finally {
      // hide loading spinner
      setIsLoading(false); // End loading
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const filterProjects = (projects, term) => {
    const lowerTerm = term?.toLowerCase();
    return projects.filter(project =>
      project.name?.toLowerCase().includes(lowerTerm) ||
      project.category?.toLowerCase().includes(lowerTerm) ||
      project.city?.toLowerCase().includes(lowerTerm)
    );
  };

  const handleUserState = (location) => {
    if (location.pathname === '/login') { // redirected by the backend after successful login
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get('token');
      const name = queryParams.get('name');
      if (token) {
        localStorage.setItem('authToken', token);
        if (name) {
          localStorage.setItem('name', name);
          setUser({ name: name});
        }
      } else {
        const storedToken = localStorage.getItem('authToken');
        const storedName = localStorage.getItem('name');
        if (storedToken && storedName) {
          setUser({ name: storedName });
        }
      }
    } else {
      const storedToken = localStorage.getItem('authToken');
      const storedName = localStorage.getItem('name');
      if (storedToken && storedName) {
        setUser({ name: storedName });
      }
    }
  };

  const logout = () => {
    // Remove the token from local storage or wherever it's stored
    localStorage.removeItem('authToken');
    localStorage.removeItem('name');
    // Optionally, redirect the user to the login page or home page
   window.location.href = '/'; // or wherever you wish to redirect
  };

  const handleLogout = async () => {
    try {
      // Send a request to the server to handle server-side logout
      await makeRequest('/logout', null, 'POST', true);
      // Clear the token from the client-side
      logout();
    } catch (error) {
      logout();
      console.error('Logout failed:', error);
    }
  };


  const handleLoginRedirect = () => {
    window.location.href = `${utils.getBaseBackendURL()}/connect/google`;
  }

  const handleVote = async (projectId, voteType) => {
    try {
      return await makeRequest(`/project/${projectId}/vote`, { voteType }, 'POST', true);
    } catch (error) {
      console.error('Error voting on project:', error);
      return Promise.reject(error);
    }
  };
  const handleVoteUpdate = (projectId, voteType, isSuccess) => {
    if (isSuccess) {
      setFilteredProjects(currentProjects => currentProjects.map(project => {
        if (project._id === projectId) {
          return {
            ...project,
            upvote_count: voteType === 'up' ? project.upvote_count + 1 : project.upvote_count,
            downvote_count: voteType === 'down' ? project.downvote_count + 1 : project.downvote_count
          };
        }
        return project;
      }));
    }
  };


  return (
    <div className="app-container">
      <Header name={user.name} onSearch={handleSearch} onLoginRedirect={handleLoginRedirect} onLogout={handleLogout} />
      <Routes>
        <Route path="/user/dashboard" element={
          <ProtectedRoute>
            <UserDashboard />
          </ProtectedRoute>
        } />
        <Route path="/projects/:projectId" element={<ProjectDetail handleVote={handleVote} handleVoteUpdate={handleVoteUpdate} />} />
        <Route path="/upcoming-project-list" element={<ProjectList filteredProjects={filteredProjects} isLoading={isLoading} handleVote={handleVote} handleVoteUpdate={handleVoteUpdate} status='upcoming'/>} />
        <Route path="/past-project-list" element={<ProjectList filteredProjects={filteredProjects} isLoading={isLoading} handleVote={handleVote} handleVoteUpdate={handleVoteUpdate} status='past'/>} />

        <Route path="/add-project" element={ <ProtectedRoute>
            <AddProject />
          </ProtectedRoute>} />
          <Route path="/edit-project/:projectId" element={ <ProtectedRoute>
            <AddProject />
          </ProtectedRoute>} />
        <Route path="/login" element={<HomePage />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutPage />} />
        {/* Add more routes as needed */}
      </Routes>
      <SideBar />
      <Footer />
    </div>
  );
};

export default App;
