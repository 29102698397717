import React, { useState, useEffect } from 'react';
import './UserDashboard.css'
import Tab from './Tab';
import PendingProjects from './PendingProjects';
import makeRequest from '../../hooks/makeRequest';
import ActiveProjects from './ActiveProjects';
import utils from '../../utils';
import { Link } from 'react-router-dom';

const UserDashboard = () => {
    const [userProjects, setUserProjects] = useState([]);
    const [userInfo, setUserInfo] = useState({});
    const [pendingProjects, setPendingProjects] = useState([]);
    const [activeProjects, setActiveProjects] = useState([]);
    const [errors, setErrors] = useState({}); // State for storing errors
    const [lastDeleted, setLastDeleted] = useState(null);

    useEffect(() => {
        const fetchPendingProjects = async () => {
            try {
                const { projects } = await makeRequest('/projects/latest/pending', null, 'GET', true);
                if (projects && projects.length > 0) {
                    setPendingProjects(projects);
                }
            } catch (error) {
                console.error('Error fetching pending projects:', error);
                // Handle error appropriately
            }
        };

        const fetchUserProjects = async () => {
            const response = await makeRequest('/user/dashboard', null, 'GET', true);
            if (response) {
                setUserProjects(response?.projects || []);
                setUserInfo(response?.user || {});
            }
        };
        const fetchActiveProjects = async () => {
            try {
                const { projects } = await makeRequest('/projects/latest/', null, 'GET', true);
                if (projects && projects.length > 0) {
                    setActiveProjects(projects);
                }
            } catch (error) {
                console.error('Error fetching pending projects:', error);
                // Handle error appropriately
            }
        };
        fetchActiveProjects();
        fetchPendingProjects();
        fetchUserProjects();
    }, [lastDeleted]);

    const handleProjectAction = async (projectId, action, reason) => {
        try {
            setErrors(prevErrors => ({ ...prevErrors, [projectId]: undefined }));
            await makeRequest(`/project/action/${projectId}`, { action, reason, projectId }, 'POST', true);
            setLastDeleted(Date.now()); // Update the state to trigger re-render
        } catch (err) {
            // Update error state for the specific project
            setErrors(prevErrors => ({ ...prevErrors, [projectId]: err.message || 'Error occurred' }));
        }
    };

    return (
        <div className="dashboard-container">
            <div className="dashboard-header">User Dashboard</div>
            <div className="dashboard-user-info">
                <p> <span>{userInfo.name}</span></p>
                <p> <span>{userInfo.email}</span></p>
                <p> Type <span>{userInfo.user_type}</span></p>
            </div>

            <Tab>
                {/* Existing projects table */}
                <div label="Projects by You">

                    <div className='dashboard-header'>Projects by you</div>
                    <table className="dashboard-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Address</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userProjects.map((project, index) => (
                                <tr key={index}>
                                    <td><a href={`${utils.getBaseWindowURL()}/projects/${project._id}`} target="_blank" rel="noopener noreferrer">{project.name}</a></td>
                                    <td>{project.address}</td>
                                    <td>{utils.getDatebyTimeZone(project.event_date)}</td>
                                    <td>{project.status}</td>
                                    <td>{project.status !== 'REJECTED' &&<Link to={`/edit-project/${project._id}`}>Edit</Link> }</td>
                                    <td>{project.status !== 'REJECTED' &&<Link onClick={() => handleProjectAction(project._id, 'rejected', 'delete')}>Delete</Link>}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pending Projects - Visible to MODs and ADMINs */}
                {['MOD', 'ADMIN'].includes(userInfo.user_type?.toUpperCase()) && (
                    <div label="Inactive Projects">
                        <PendingProjects pendingProjects={pendingProjects} />
                    </div>
                )}

                {/* Active Projects - Visible to MODs and ADMINs */}
                {['MOD', 'ADMIN'].includes(userInfo.user_type?.toUpperCase()) && (
                    <div label="Active Projects">
                        <ActiveProjects activeProjects={activeProjects} />
                    </div>
                )}
            </Tab>
        </div>
    );
};

export default UserDashboard;
